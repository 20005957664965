/* index.css */

* {
  margin: 10px 2px;
}

html, body {
  margin: 0 !important;
  padding: 0 !important;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: #e0e0e0 !important;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}